import React, { useEffect, useState } from 'react'
import Footer from '../Home/Footer'
import Navbar from '../Home/Navbar'
import Signup from "../Pages/Signup"
import Loader from './loader'
import './registation.css'
export default function Registation() {

  return (
    <>   
      <div className="registations">
     

    
      
          <object className='Registationpage' type="text/html" data="https://www.rmageddon23.tech/" >
          </object>
        
 
        

       

      </div> 
    </>
  )
}
