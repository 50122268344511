export const alum = [
    {
        "_id":"1",
        "Name":"Prasanna Tungar",
        "image":"https://ik.imagekit.io/wqweoudym/1636437851103_Jd4NzchK_.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1657360901877",
        "Review":"RNXG is a club where you will get to practically apply your knowledge. You will get the opportunity to explore yourself and to achieve your goals. You will make friends for life who  are like-minded as you ie growth oriented. "
    },  
      {
        "_id":"2",
        "Name":"Akshay Mirashe",
        "image":"https://ik.imagekit.io/wqweoudym/a6e47601-d055-4cf8-a81f-ce40d53e85e8_DjR3RGwye.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1651487056254",
        "Review":"RNXG is a very collaborative and interactive space for students in college, students here can work on their innovative projects, competitions, discuss startup ideas with friends and seniors and many more such. "
    },
    
]