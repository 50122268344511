 export const event = [
{
     "Event":"Rmageddon",
     "Year":" 2020",
     "Youtube":"https://www.youtube.com/embed/BKbOyQMPtY8",
     "Youredirect":"https://www.youtube.com/watch?v=BKbOyQMPtY8"
    },
    {
     "Event":"Rnxg Achivements",
     "Year":" 2018-19",
     "Youtube":"https://www.youtube.com/embed/DZhzVoBmWaY",
     "Youredirect":"https://www.youtube.com/watch?v=DZhzVoBmWaY"
    },    
    {
     "Event":"Robocon-2019 Journey",
     "Year":" 2019",
     "Youtube":"https://www.youtube.com/embed/kguK-_5pCgI",
     "Youredirect":"https://youtu.be/kguK-_5pCgI"
    },   
     {
     "Event":"Robocon-2022 Journey",
     "Year":" 2022",
        "Youtube":"https://www.youtube.com/embed/NLV5867H_ME",
        "Youredirect":"https://youtu.be/NLV5867H_ME"
    }, 
  {
           "Event":"Rmageddon",
     "Year":" 2023",
           "Youtube":"https://www.youtube.com/embed/PPvDQY-0uI0",
           "Youredirect":"https://youtu.be/PPvDQY-0uI0"
    },





 ]